import { useState } from "react";
import { useWeb3React } from "@web3-react/core";

import { Assets } from "../../Assets";
import HeaderStyles from "./Header.module.css";

import SocialLinks from "../../Constants/SocialLinks";
import HeaderLinks from "../../Constants/HeaderLinks";
import MobileMenu from "./MobileMenu";
import { connectors } from "../../Helpers/connectors";
import { onClickMetamask, refreshState } from "../../Helpers/web3";

const Header = ({ showConnectBtn = false }) => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const toggleMenu = () => setIsMenuActive(!isMenuActive);
  const { deactivate, activate, account } = useWeb3React();
  const isConnected = !!account;

  const onClickDisconnect = async () => {
    try {
      refreshState();
      deactivate();
    } catch (error) {
      console.log(error);
    }
  };

  const onClickConnect = async () => {
    try {
      await onClickDisconnect();
      const connector = onClickMetamask();

      if (connector) {
        activate(connectors.injected);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Item = ({ text = "", href = "" }) => (
    <div className="flex h-full border-r-[1px] border-[#22252E] items-center">
      <div className="pl-[28px] pr-[28px]">
        <a
          href={href}
          className="text-white text-[12px] font-[Maax-Medium]"
          target={"_self"}
        >
          {text}
        </a>
      </div>
    </div>
  );

  const Logo = ({ className = "" }) => (
    <a href={window.location.origin}>
      <img
        src={Assets.mightymetaSmall}
        alt="Mighty Meta"
        className={className}
      />
    </a>
  );

  const TopHeader = () => (
    <div>
      {isMenuActive ? <MobileMenu /> : null}

      {/* Desktop */}
      <div className="flex bg-black h-[48px] w-full hide-in-mobile">
        <Item text="MIGHTY JAXX" href={HeaderLinks.mightyjaxx} />
        <Item text="MIGHTY ALLSTARS" href={HeaderLinks.mightyallstars} />
        <Item text="MIGHTY META" href={window.location.origin} />
      </div>

      {/* Mobile */}
      <div
        className={`flex bg-[#3077ff] ${
          isMenuActive ? "z-20 fixed" : ""
        }  h-[72px] w-full items-center pl-[4%] pr-[4%] justify-between hide-in-desktop`}
      >
        <Logo className={"h-[42px] ml-[-7px]"} />

        <div
          className={`${HeaderStyles["App-header-hamburger"]} ${HeaderStyles["hamburger-black"]}`}
        >
          <input
            type="checkbox"
            onClick={toggleMenu}
            checked={!!isMenuActive}
            readOnly
          />
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );

  const BottomHeader = () => (
    <div className={`${HeaderStyles["App-header"]}`}>
      <div className={HeaderStyles["App-header-logo-nav"]}>
        <Logo className={HeaderStyles["App-mightymeta-logo"]} />
      </div>

      <div className={`${isMenuActive ? HeaderStyles.active : ""}`}>
        <ul
          className={`${HeaderStyles["App-header-nav-options"]} mt-1 ml-[53px]`}
        >
          <li className={HeaderStyles["App-header-nav-option"]}>
            <a href={SocialLinks.twitter}>TWITTER</a>
          </li>
          <li className={HeaderStyles["App-header-nav-option"]}>
            <a href={SocialLinks.discord}>DISCORD</a>
          </li>
        </ul>
      </div>

      {showConnectBtn ? (
        <button
          onClick={isConnected ? onClickDisconnect : onClickConnect}
          className={`${HeaderStyles["connect-wallet-btn"]} `}
        >
          {isConnected ? "DISCONNECT" : "CONNECT WALLET"}
        </button>
      ) : null}
    </div>
  );

  return (
    <div className="flex flex-col w-full ">
      <TopHeader />
      <BottomHeader />
    </div>
  );
};

export default Header;
